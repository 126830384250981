import React from "react";

import SEO from "../components/shared/seo/seo.component";

const Biografia = () => {
  return (
    <>
      <SEO title="Biografía" />
      <p>Biografía</p>
    </>
  );
};

export default Biografia;
